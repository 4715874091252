import { render, staticRenderFns } from "./previewresume.vue?vue&type=template&id=621bdc35&scoped=true&"
import script from "./previewresume.vue?vue&type=script&lang=js&"
export * from "./previewresume.vue?vue&type=script&lang=js&"
import style0 from "./previewresume.vue?vue&type=style&index=0&id=621bdc35&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621bdc35",
  null
  
)

export default component.exports